<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters>

      <v-col cols="12" md="10" class="offset-md-1">

        <Recaptcha v-if="!showAskResetPasswordDialog" ref="recaptcha" @verify="(response) => login(response)" />

        <v-row align="center" no-gutters>

          <!-- Title -->
          <v-col cols="12" class="text-center bg-white mt-4">
            <h1 style="font-size: 1.3rem;" class="text-center w-100">{{$t('account.login.title')}}</h1>
            <v-divider class="mt-2"/>
          </v-col>

          <!-- Form -->
          <v-col cols="12"  class="mt-12">
            <v-form>
              <v-row no-gutters>

                <!-- Email -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    name="login"
                    :label="$t('account.login.labels.email')"
                    type="text"
                    hide-details
                    v-model="form.email">
                    <template v-slot:prepend>
                      <img width="20" src="@/assets/icons/controls/user_off.svg" />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Password -->
                <v-col cols="12" class="mt-6">
                  <v-text-field
                    outlined
                    dense
                    id="password"
                    name="password"
                    :label="$t('account.login.labels.password')"
                    type="password"
                    @keyup.enter.native="login"
                    hide-details
                    v-model="form.password">
                    <template v-slot:prepend>
                      <img width="20" src="@/assets/icons/controls/password.svg" />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Btn ask reset password -->
                <v-col cols="12" class="mt-1 text-center">
                  <v-btn
                    text
                    class="py-0 mt-0"
                    @click="showAskResetPasswordDialog = true">
                    {{$t('account.login.buttons.forgotPassword')}}
                  </v-btn>
                </v-col>

                <v-col cols="12" class="mt-2">
                  <AlertBar />
                </v-col>

                <!-- Button login -->
                <v-col cols="12" md="10" class="offset-md-1 text-center mt-6">
                  <v-btn
                    large
                    style="width: 200px; text-transform: none !important;border-radius: 10px !important;"
                    color="primary"
                    class="font-weight-bold"
                    :loading="isLoading"
                    @click="executeRecaptcha">
                    {{$t('account.login.buttons.login')}}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <LoginSocialButtons />
                </v-col>

                <v-col cols="12" class="my-12">
                  <v-row justify="center" no-gutters>
                    <div>
                      <span>{{$t('account.login.labels.create_account')}}</span>
                      <router-link :to="{name: 'Registration'}" class="ml-1 text-decoration-none">{{$t('account.login.buttons.register')}}</router-link>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

    <AskResetPassword
      v-if="showAskResetPasswordDialog"
      :value="showAskResetPasswordDialog"
      @close="showAskResetPasswordDialog = false" />

  </v-container>
</template>

<script>
import formRules from '@/mixins/formRules';
import cloneDeep from "clone-deep";

export default {

  name: "Login",

  components: {
    Recaptcha: () => import("@/components/Common/Recaptcha"),
    AskResetPassword: () => import("@/components/Account/Dialogs/AskResetPasswordDialog"),
    AlertBar: () => import("@/components/Common/AlertBar"),
    LoginSocialButtons: () => import("@/components/Account/LoginSocialButtons")
  },

  mixins: [formRules],

  data() {
    return {

      form: {},

      default_form: {
        email: "",
        password: ""
      },

      isLoading: false,

      showSupportDialog: false, showAskResetPasswordDialog: false,

      pageTitle: this.$t('account.login.pageTitle'),

      facebookSignInParams: {
          scope: 'email',
          return_scopes: true
      },


    }
  },

  created() {
    this.$store.dispatch("logout");

    this.$store.commit("pageHead/setTitle", this.$t('account.login.pageTitle'))
    this.form = cloneDeep(this.default_form);


  },

  methods: {

    login(recaptcha) {
      this.$store.commit('alert/hideAlert');

      if (this.form.username === "" || this.form.password === "") return this.$store.commit('alert/showError', this.$t('common.errors.ALL_REQUIRED'));

      this.isLoading = true;
      this.form["g-recaptcha-response"] = recaptcha;

      this.$http.post('/map/auth/login', this.form).then(res => {
        // OK
        if (res.status === 200) {
          this.$session.start();

          this.$session.set('jwt', res.data.token);

          if (res.data.user._id)
            this.$session.set('id', res.data.user._id);

          this.$session.set("person.firstname", res.data.user.person.firstname);
          this.$session.set("person.name", res.data.user.person.name);
          this.$session.set("email", res.data.user.email);
          this.$session.set("plan", res.data.subscription.plan);
          this.$router.push({name: "Home"});

        }
      })
      .catch(err => {
        this.form.password = '';

        if (err.response && err.response.status === 400) {
          if (err.response.type === 'TOO_MANY_ATTEMPTS') this.$store.commit('alert/showError',
            this.$t('account.login.errors.TOO_MANY_ATTEMPTS')); else this.$store.commit('alert/showError', this.$t('account.login.errors.LOGIN_NOT_VALID'));
        } else this.$store.commit('alert/showError', this.$t('common.errors.500'));
      })
      .finally(() => {
        this.isLoading = false;
        this.form.password = '';
      })
    },

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    }
  }
}
</script>

<style scoped>

</style>